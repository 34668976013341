import React from 'react'
import { Layout, SEO } from '@global'

const IndexPage = () => {
  return (
    <div className="relative">
      <SEO title="Components" />
      <Layout>
        
      </Layout>
    </div>
  )
}
export default IndexPage
